/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { NumberInput } from '@/components/blocks/NumberInput';
import { OneClick } from '@/components/blocks/OneClick';
import { OrderItemTableRowData } from '@/components/content/OrderItemTable/parts/Table';
import { orderItemTableQuantitySX } from '@/components/content/OrderItemTable/styles/orderItemTableQuantity';
import { productDetailsQuantitySX } from '@/components/content/OrderItemTable/styles/quantity';
import { useOrderItemTableRow } from '@/data/Content/OrderItemTable';
import { useLocalization } from '@/data/Localization';
import { useSettings } from '@/data/Settings';
import { ContentContext } from '@/data/context/content';
import { EventsContext } from '@/data/context/events';
import { formatNumberValue } from '@/utils/formatNumberValue';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Stack, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { FC, MouseEvent, useCallback, useContext, useMemo } from 'react';

export const OrderItemQuantity: FC<{
	readOnly?: boolean;
	mini?: boolean;
}> = ({ readOnly, mini = false }) => {
	const orderItemTableNLS = useLocalization('OrderItemTable');
	const {
		quantity: { quantity, onChange },
		price,
		details,
		freeGift,
	} = useContext(ContentContext) as OrderItemTableRowData & ReturnType<typeof useOrderItemTableRow>;
	const { onRemoveFromCart } = useContext(EventsContext);
	const { settings } = useSettings();

	const removeFromCart = async (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		await onChange(0);

		onRemoveFromCart({
			gtm: {
				partNumber: details.partNumber,
				name: details.name,
				price: `${price.orderItemPrice}`,
				currency: price.currency,
				quantity: `${quantity}`,
				seller: details?.seller,
				sellerId: details?.sellerId,
				orgName: '', // TODO: specify selected org-name
				orgId: '', // TODO: specify selected org
				storeName: settings.storeName,
				settings,
			},
		});
	};

	const quantityChange = useCallback(
		(updatedQuantity: number | null) => {
			if (updatedQuantity !== null && updatedQuantity > 0 && updatedQuantity !== quantity) {
				onChange(updatedQuantity);
			}
		},
		[onChange, quantity]
	);

	const debouncedQuantityChange = useMemo(
		() => debounce((updatedQuantity) => quantityChange(updatedQuantity), 500),
		[quantityChange]
	);

	const fractionalIncrementQtyLimit = details.fractionalIncrementQtyLimit;

	const adjustedMin = details.specialOrderFlag
		? details.specialOrderQtyLimit
			? details.specialOrderQtyLimit
			: 1
		: fractionalIncrementQtyLimit;

	return (
		<>
			{readOnly || freeGift ? (
				<Stack alignItems="center" spacing={1} direction="row" sx={orderItemTableQuantitySX}>
					{mini ? (
						<Typography>
							{formatNumberValue({
								value: quantity,
								precision: 3,
								decimalSeparator: '.',
								thousandSeparator: ',',
								fractionalIncrementQtyLimit,
							})}
						</Typography>
					) : (
						<Typography>
							{formatNumberValue({
								value: quantity,
								precision: 3,
								decimalSeparator: '.',
								thousandSeparator: ',',
								fractionalIncrementQtyLimit,
							})}
						</Typography>
					)}
				</Stack>
			) : (
				<>
					{details.specialOrderFlag ? (
						<Stack alignItems="center" spacing={1} direction="row">
							<Typography p={1} color="primary.main" variant="body1">
								{'* Only purchasable in quantities of ' + details.specialOrderQtyLimit}
							</Typography>
						</Stack>
					) : null}
					<Stack alignItems="center" spacing={1} direction="row" sx={orderItemTableQuantitySX}>
						<NumberInput
							onChange={debouncedQuantityChange}
							value={quantity}
							min={adjustedMin}
							precision={3}
							sx={productDetailsQuantitySX}
							disabled={readOnly}
							showControls
							disallowEmptyOnBlur={true}
							data-testid="order-item-quantity"
							id="order-item-quantity"
							disableTextInput={details.specialOrderFlag}
							fractionalIncrementQtyLimit={fractionalIncrementQtyLimit}
						/>

						<OneClick wrapper="icon" onClick={removeFromCart} spin={true} spinSize={24}>
							<DeleteOutlineIcon
								color="text"
								titleAccess={orderItemTableNLS.Labels.RemoveFromCart.t()}
							/>
						</OneClick>
					</Stack>
				</>
			)}
		</>
	);
};
